/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */


// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
    },
  };
  config.settings = {
    ...config.settings,
    logo: '/customisations/components/theme/Logo/Logo.png',
    devProxyToApiPath: 'http://localhost:8080/Kids_Website',
    //slate: {
    //  ...config.settings.slate,
    //  toolbarButtons: [...config.settings.slate.toolbarButtons, 'align-center'],
    //},
    
  };
  config.blocks.requiredBlocks = [];
  //{ console.log(config.settings) }
  
  return config;
}
